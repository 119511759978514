<template>
    <div>
        <header-portrate />

        <div>
            <bread-crumbs :items="items"></bread-crumbs>
            
            <tabs-comp :tabs="tabs" />

            <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:15px !important;margin-bottom:50px !important`">
                <v-col cols="12">
                    <label>{{lang.printing_header}}</label>
                    <b-form-textarea v-model="printing.header" class="inborder" style="height:200px;direction:ltr" />
                </v-col>
                <v-col cols="12" v-if="$store.state.licenseType.POSREST || $store.state.licenseType.POSRET">
                    <label>{{lang.printing_header}} - POS</label>
                    <b-form-textarea v-model="printing.pos_header" class="inborder" style="height:200px;direction:ltr" />
                </v-col>
                <v-col cols="12">
                    <label style="display:none">{{lang.invoice_body}}</label>
                    <label>نص مخصص يضاف اعلى الفاتورة </label>
                    <b-form-textarea v-model="printing.invoice_body" class="inborder" style="height:200px;direction:ltr" />
                </v-col>
                <v-col cols="12">
                    <label style="display:none">{{lang.voucher_body}}</label>
                    <label>نص مخصص يضاف اعلى عرض السعر</label>
                    <b-form-textarea v-model="printing.voucher_body" class="inborder" style="height:200px;direction:ltr" />
                </v-col>
                <v-col cols="12" style="display:none">
                    <label>{{lang.card_body}}</label>
                    <b-form-textarea v-model="printing.card_body" class="inborder" style="height:200px;direction:ltr" />
                </v-col>
                <v-col cols="12">
                    <label>{{lang.footer_extra}}</label>
                    <b-form-textarea v-model="printing.ex_footer" class="inborder" style="height:200px;direction:ltr" />
                </v-col>
                <v-col cols="12" v-if="$store.state.licenseType.POSREST || $store.state.licenseType.POSRET">
                    <label>{{lang.printing_footer}} - POS</label>
                    <b-form-textarea v-model="printing.pos_footer" class="inborder" style="height:200px;direction:ltr" />
                </v-col>
                <v-col cols="12">
                    <label>{{lang.printing_footer}}</label>
                    <b-form-textarea v-model="printing.footer" class="inborder" style="height:200px;direction:ltr" />
                </v-col>
                <v-col cols="12" class="text-center">
                    <v-btn class="btn" @click="save()" style="background:darkgreen;color:#fff;width:120px;margin:5px">{{lang.save}}</v-btn>
                    <v-btn class="btn" @click="view()" style="background:red;color:#fff;width:120px;margin:5px">{{lang.view_page}}</v-btn>
                </v-col>
            </v-row>
        </div>
        <Footer />
    </div>
</template>

<script>

import axios from 'axios'
import headerPortrate from '@/components/Header-Portrate.vue'
import breadCrumbs from '@/components/breadCrumbs.vue'
import tabsComp from '@/components/tabsComp.vue'
import Footer from '@/components/Footer.vue'

export default{
    name: 'printing',
    components: {headerPortrate,breadCrumbs,tabsComp,Footer},
    data() {
        return {
            printings: {
                header: '',
                invoice_body: '',
                voucher_body: '',
                card_body: '',
                footer: '',
                pos_header: '',
                pos_footer: '',
                ex_footer: '',
            },
            printing: {
                header: '',
                invoice_body: '',
                voucher_body: '',
                card_body: '',
                footer: '',
                ex_footer: '',
                pos_header: '',
                pos_footer: '',
            },
        }
    },
    computed: {
        
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        items: function(){
            return  {
                text: this.lang.customers,
                disabled: true,
                href: '/clientList',
            };
        },
        tabs: function(){
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        }
    },
    methods: {
        view(){
            window.open("../api/print.php?test","_blank")
        },
        save(){
            const post = new FormData();
            post.append('type', "SavePrint");
            post.append("auth" , this.$cookies.get(this.$COOKIEPhase));
            post.append("auth" , this.$cookies.get(this.$COOKIEPhase));
            post.append('data[header]',btoa(unescape(encodeURIComponent(this.printing.header))));
            post.append('data[invoice_body]',btoa(unescape(encodeURIComponent(this.printing.invoice_body))));
            post.append('data[voucher_body]',btoa(unescape(encodeURIComponent(this.printing.voucher_body))));
            post.append('data[card_body]',btoa(unescape(encodeURIComponent(this.printing.card_body))));
            post.append('data[footer]',btoa(unescape(encodeURIComponent(this.printing.footer))));
            post.append('data[ex_footer]',btoa(unescape(encodeURIComponent(this.printing.ex_footer))));
            post.append('data[pos_footer]',btoa(unescape(encodeURIComponent(this.printing.pos_footer))));
            post.append('data[pos_header]',btoa(unescape(encodeURIComponent(this.printing.pos_header))));
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(() =>{

                this.preparedata();
            })
        },
        preparedata(){
            const post = new FormData();
            post.append('type', "PrintSet");
            post.append("auth" , this.$cookies.get(this.$COOKIEPhase));
            post.append('data[header]',this.printing.header);
            post.append('data[invoice_body]',this.printing.invoice_body);
            post.append('data[voucher_body]',this.printing.voucher_body);
            post.append('data[card_body]',this.printing.card_body);
            post.append('data[footer]',this.printing.footer);
            post.append('data[ex_footer]',this.printing.ex_footer);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) =>{
                // // console.log(response.data)
                this.printings = response.data.results.data;
                Object.keys(response.data.results.data).forEach(key => {
                    this.printing[key] = decodeURIComponent(escape(atob(response.data.results.data[key])))
                })
            })
        },
    },
    created() {
        this.preparedata();
    },
}
</script>